<template>
  <div class="bg p-3">
    <h3>Room</h3>
    Room Id: {{ roomId }}<br/>
    <room-logs :roomId="roomId"/>
  </div>
</template>

<script>
import roomLogs from "@/components/roomLogs";

export default {
  name: 'app',
  components: {
    roomLogs
  },
  data() {
    return {
      roomId: "",
    }
  },
  mounted() {
    if (this.$route.query.roomId)
      this.roomId = this.$route.query.roomId;
  }
}
</script>

<style scoped>

.bg {
  background-color: powderblue;
}

.log {
  font-family: monospace;
  white-space: nowrap;
}

</style>
